h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 0.5em 0;
}

h1 {
    font-size: 1.7em;
    line-height: 1.9em;
}

h2 {
    font-size: 1.5em;
    line-height: 1.7em;
}

h3 {
    font-size: 1.3em;
    line-height: 1.5em;
}

h4 {
    font-size: 1.1em;
    line-height: 1.4em;
}

h5 {
    font-size: 0.9em;
    line-height: 1.3em;
}

h6 {
    font-size: 0.8em;
    line-height: 1.2em;
}

ul {
    margin-bottom: 20px;
    margin-left: 20px;
}

p {
    margin: 0 0 10px;
}