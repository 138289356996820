@font-face {
  font-family: 'Poppins';
  font-display: fallback;
  src: url('/assets/fonts/Poppins-Regular.ttf');
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

html {
  scroll-behavior: smooth
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
  line-height: 21px;
  font-size: 16px;
  text-align: center;
}

body.ReactModal__Body--open {
  overflow: hidden;
}
