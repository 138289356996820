strong {
    font-weight: bolder;
}

div[data-slate-editor] > ul{
    margin-left: 20px !important;
    padding-left: 0;
}

ol {
    padding-inline-start: 5px;
    margin-left: 13px !important;
}
